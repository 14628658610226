import React, {useState} from 'react';
import { Formik } from "formik";
import {connect} from "react-redux";
import {graphql, StaticQuery} from "gatsby";
import * as emailjs from 'emailjs-com';
import Img from "../../../components/Img";

import FormFeedback from '../components/FormFeedback';
import {Modal} from '../../../components/';


const FormFeedbackContainer = props => {

    const {
        languageSelect,
        formLabelName,
        formPlaceholderName,
        formLabelEmail,
        formPlaceholderEmail,
        formLabelPhone,
        formPlaceholderPhone,
        formLabelText,
        formPlaceholderText,
        buttonName,
        consentForm,
        errorLabel,
        errorLabelName,
        successSubtitle,
        successMessage
    } = props;

    const [formName, setFormName] = useState('');
    const [modalSuccess, setModalSuccess] = useState(false);

    const closeModal = () => {
        setModalSuccess(false);
    };

    return (
        <StaticQuery
            query={graphql`
              query {
                contentfulFeedbackHome {
                  subtitle_ru
                  subtitle_en
                  subtitle_cn
                  desc_ru
                  desc_en
                  desc_cn
                  img{
                    title
                    gatsbyImageData
                  }
                }
              }
            `}
            render={data => {

                return (
                    <section className="section section-form-feedback">
                        <div className="container">
                            <div className="frame">
                                <div className="frame-item frame-top frame-item_color_black">
                                    <div className="frame-item__el frame-top__frame-item-el_right"></div>
                                </div>
                                <div className="frame-item frame-bottom frame-item_color_black">
                                    <div className="frame-item__el frame-bottom__frame-item-el_left"></div>
                                </div>
                            </div>
                            <div className="section-head">
                                <h2 className="subtitle subtitle_lvl_two">{data.contentfulFeedbackHome["subtitle_" + languageSelect]}</h2>
                                <div className="subtitle-desc">
                                    {data.contentfulFeedbackHome["desc_" + languageSelect]}
                                </div>
                            </div>
                            <div className="section-form-feedback-wrapper">
                                <Formik
                                    initialValues={{
                                        name: "",
                                        email: "",
                                        phone: "",
                                        message: ""
                                    }}
                                    validate={(values) => {
                                        let errors = {};
                                        if (!values.name) {
                                            errors.name = errorLabel;
                                        } else if (
                                            /^[0-9]/i.test(
                                                values.name)
                                        ) {
                                            errors.name = errorLabelName;
                                        }

                                        if (!values.email) {
                                            errors.email = errorLabel;
                                        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                                            errors.email = 'Invalid email address';
                                        }

                                        if (!values.phone.length < 18) {
                                          errors.phone = errorLabel;
                                        }

                                        if (!values.message) {
                                            errors.message = errorLabel;
                                        }
                                        //check if my values have errors
                                        return errors
                                    }}
                                    onSubmit={(values) => {
                                        let templateParams = {
                                            from_name: 'Оставить заявку с главной страницы',
                                            message_html: "Имя: " + values.name + "; " + "Email: " + values.email + "; " + "Телефон: " + values.phone + "; " + "Сообщение: " + values.message,
                                        };
                                        emailjs.send(
                                            'yandex',
                                            process.env.emailTemplateJs,
                                            templateParams,
                                            process.env.emailIdJs
                                        );

                                        setFormName(values.name);
                                        setModalSuccess(true);
                                        values.name = "";
                                        values.email = "";
                                        values.message = "";
                                    }}
                                    render={
                                        props => (
                                            <FormFeedback {...props}
                                                          labelName={formLabelName}
                                                          placeholderName={formPlaceholderName}
                                                          labelEmail={formLabelEmail}
                                                          placeholderEmail={formPlaceholderEmail}
                                                          labelPhone={formLabelPhone}
                                                          placeholderPhone={formPlaceholderPhone}
                                                          labelText={formLabelText}
                                                          placeholderText={formPlaceholderText}
                                                          buttonName={buttonName}
                                                          buttonConsent={consentForm}
                                            />
                                        )
                                    }
                                />

                                <div className="section-form-feedback-img">
                                    <Img fluid={data.contentfulFeedbackHome.img}
                                         alt={data.contentfulFeedbackHome.img.title}
                                         className="section-form-feedback-img__item"
                                    />
                                </div>
                            </div>
                            <Modal title={successSubtitle + ', ' + formName + "!"} isOpen={modalSuccess} onSubmit={closeModal}>
                                <div className="modal-text">{successMessage}</div>
                            </Modal>
                        </div>

                    </section>
                )
            }}
        />

    );
};


let mapStateToProps = (state) => {
    return {
        languageSelect: state.languageSelect["languageSelect"],
        formLabelName: state.form["form-feedback"]['formLabelName_' + state.languageSelect["languageSelect"]],
        formPlaceholderName: state.form["form-feedback"]['formPlaceholderName_' + state.languageSelect["languageSelect"]],

        formLabelEmail: state.form["form-feedback"]['formLabelEmail_' + state.languageSelect["languageSelect"]],
        formPlaceholderEmail: state.form["form-feedback"]['formPlaceholderEmail_' + state.languageSelect["languageSelect"]],

        formLabelPhone: state.form["form-feedback"]['formLabelPhone_' + state.languageSelect["languageSelect"]],
        formPlaceholderPhone: state.form["form-feedback"]['formPlaceholderPhone_' + state.languageSelect["languageSelect"]],

        formLabelText: state.form["form-feedback"]['formLabelText_' + state.languageSelect["languageSelect"]],
        formPlaceholderText: state.form["form-feedback"]['formPlaceholderText_' + state.languageSelect["languageSelect"]],

        buttonName: state.form["form-feedback"]['button_' + state.languageSelect["languageSelect"]],
        consentForm: state.form['consent_' + state.languageSelect["languageSelect"]],
        errorLabel: state.form['errorLabel_' + state.languageSelect["languageSelect"]],
        errorLabelName: state.form['errorLabelName_' + state.languageSelect["languageSelect"]],

        successSubtitle: state.form['successSubtitle_' + state.languageSelect["languageSelect"]],
        successMessage: state.form['successMessage_' + state.languageSelect["languageSelect"]],
    }
};

export default connect(mapStateToProps)(FormFeedbackContainer);
