import React from 'react';
import Img from "../../../components/Img";


const ReviewsSliderItem = ({name, companyName, review, img, imgAlt}) => {
    return (
        <div className="reviews-slider-item">
            <div className="reviews-slider-img">
                <Img fluid={img} alt={imgAlt} className="reviews-slider-img__item"/>
            </div>
            <div className="reviews-slider-content">
                <div className="reviews-slider__name">{name}</div>
                <div className="reviews-slider__company">{companyName}</div>
                <div className="reviews-slider__text">
                    {review}
                </div>
            </div>
        </div>
    );
};

export default ReviewsSliderItem;
