import React from 'react';
import Img from "../../../components/Img";


const PartnersItem = ({img}) => {
    return (
        <div className="partners-slider__item">
            <Img fluid={img} />
        </div>
    );
};


export default PartnersItem;
