import React from 'react';


import Banner from '../components/Banner';
import Advantages from "../../Advantages/containers/Advantages";





const BannerContainer = () => {

    return (
        <section className="section section-banner">
            <Banner classWrapper={'banner-wrapper-advantages'}>
                <Advantages className={'banner__advantages'}/>
            </Banner>
        </section>
    );

};


export default BannerContainer;