import React from 'react';
import Img from "../../../components/Img";


const AwardsItem = ({img}) => {
    return (
        <div className="awards-slider__item">
            <Img fluid={img}/>
        </div>
    );
};


export default AwardsItem;
