import React, { useState, useEffect } from 'react';
import { Player, ControlBar, BigPlayButton } from 'video-react';

import { Header, HeaderScrolling } from '../../../components';
import Advantages from "../../Advantages/containers/Advantages";
import BannerAdvantages from "../../Banner/containers/BannerAdvantages";

const MainBlock = ({ subtitle, desc, buttonName, videoBg, videoPoster }) => {
  const mainVideo = React.createRef();

  const [videoFullScreen, setVideoFullScreen] = useState(false);

  useEffect(() => {
    mainVideo.current.subscribeToStateChange(handleStateChange.bind(this));
  });

  const buttonFullScreenVideo = () => {
    mainVideo.current.toggleFullscreen();
    mainVideo.current.play();
  };

  const handleStateChange = (state, prevState) => {
    setVideoFullScreen(state.isFullscreen);
  };

  return (
    <section className='section-main section'>
      <Player
        muted={!videoFullScreen}
        autoPlay={true}
        loop={true}
        autobuffer
        fluid={false}
        height='100%'
        width='100%'
        src={videoBg}
        playsInline={true}
        poster={videoPoster}
        ref={mainVideo}
      >
        <ControlBar
          autoHide={true}
          disableDefaultControls={false}
          disableCompletely={false}
        />
        <BigPlayButton position='center' />
      </Player>

      <div className='container'>
        <div className='frame'>
          <div className='frame-item frame-top'>
            <div className='frame-item__el frame-top__frame-item-el_left'></div>
            <div className='frame-item__el frame-top__frame-item-el_right'></div>
          </div>
          <div className='frame-item frame-bottom'>
            <div className='frame-item__el frame-bottom__frame-item-el_left'></div>
            <div className='frame-item__el frame-bottom__frame-item-el_right'></div>
          </div>
        </div>
        <div className='section-main-wrapper'>
          <Header />
          <HeaderScrolling />
          <div className='section-main-content'>
            <h1 className='subtitle subtitle_lvl_one'>{subtitle}</h1>
            <div className='section-main-content__desc'>{desc}</div>
            <button className='button' onClick={buttonFullScreenVideo}>
              <div className='button-inner'>
                {buttonName}
                <div className='button__icon'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='12'
                    height='15'
                    viewBox='0 0 12 15'
                    fill='none'
                  >
                    <path
                      fillRule='evenodd'
                      clipRule='evenodd'
                      d='M0.30816 1.29275e-05C0.298383 -8.17237e-05 0.288607 0.000343428 0.278868 0.00128683C0.202826 0.00712045 0.131679 0.0442104 0.0798829 0.105021C0.0280867 0.165831 -0.000473252 0.2458 5.93368e-06 0.328679V14.6766C0.00044954 14.7337 0.0146917 14.7897 0.0413064 14.839C0.067921 14.8883 0.105974 14.9291 0.151655 14.9573C0.197337 14.9856 0.249043 15.0003 0.301597 15C0.354151 14.9997 0.405708 14.9844 0.451106 14.9556L11.8505 7.78226C11.8959 7.7536 11.9337 7.71244 11.9599 7.66292C11.9862 7.6134 12 7.55725 12 7.50009C12 7.44294 11.9862 7.38679 11.9599 7.33727C11.9337 7.28774 11.8959 7.24659 11.8505 7.21793L0.451106 0.0445994C0.407655 0.0169284 0.358495 0.00159512 0.30816 1.29275e-05V1.29275e-05Z'
                      fill='white'
                    />
                  </svg>
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
      {/*<Advantages className={'banner__advantages'}/>*/}
      <BannerAdvantages />
    </section>
  );
};

export default MainBlock;
