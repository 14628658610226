import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Banner = ({children, classWrapper}) => {



    return (
        <div className="banner">
            <div className="container">
                <div className={classNames("banner-wrapper", classWrapper)}>
                    {children}
                </div>
            </div>

        </div>
    );
};

Banner.propTypes = {
    subtitle: PropTypes.array,
    subtitleColor: PropTypes.string,
    buttonName: PropTypes.string,
    buttonColor: PropTypes.string,
    img: PropTypes.object,
    imgAlt: PropTypes.string,
};

export default Banner;