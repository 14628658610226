import React from 'react';
import {Link} from "gatsby";
import {BigPlayButton, ControlBar, Player} from "video-react";


const AboutHome = ({subtitle, descSubtitle, description, buttonName}) => {

    return (
        <section className="section section-about">
            <div className="container">
                <div className="frame">
                    <div className="frame-item frame-top frame-item_color_black">
                        <div className="frame-item__el frame-top__frame-item-el_right"></div>
                    </div>
                    <div className="frame-item frame-bottom frame-item_color_black">
                        <div className="frame-item__el frame-bottom__frame-item-el_left"></div>
                        <div className="frame-item__el frame-bottom__frame-item-el_right"></div>
                    </div>
                </div>
                <div className="section-head">
                    <h2 className="subtitle subtitle_lvl_two">{subtitle}</h2>
                    {
                        descSubtitle ?
                            <div className="subtitle-desc">
                                {descSubtitle}
                            </div>
                        : null
                    }

                </div>
                <div className="section-about-content">
                    {description}
                </div>
                <div className="section-about-footer">
                    <Link to="/about/" className="button-detail-link">
                        <span className="button-detail-link-hover button-detail-link-hover_bottom"></span>
                        <span className="button-detail-link-hover button-detail-link-hover_top"></span>
                        {buttonName}
                    </Link>
                </div>
            </div>

        </section>
    );
};

export default AboutHome;
