import React from 'react';
import {connect} from "react-redux";
import {graphql, StaticQuery} from "gatsby";
import classNames from 'classnames';

import AdvantagesItem from '../components/AdvantagesItem';


const Advantages = ({languageSelect, className}) => {
    return (
        <StaticQuery
            query={graphql`
              query  {
                allContentfulAdvantages {
                    edges {
                      node {
                        id
                        elements {
                          id
                          img {
                            title
                            file {
                              url
                            }
                          }
                          subtitle_ru {
                            raw
                          }
                          subtitle_en {
                            raw
                          }
                          subtitle_cn {
                            raw
                          }
                        }
                      }
                    }
                  }
              }
            `}
            render={data => {

                const advantages = data.allContentfulAdvantages.edges[0].node.elements.map(item => {
                    return (
                        <AdvantagesItem key={item.id}
                                        img={item.img.file.url}
                                        imgTitle={item.img.title}
                                        name={item["subtitle_" + languageSelect]}
                        />
                    );
                });

                return (
                    <div className={classNames("advantages", className)}>
                        {advantages}
                    </div>
                );
            }}
        />

    );
};



let mapStateToProps = (state) => {
    return {
        languageSelect: state.languageSelect["languageSelect"],
    }
};

export default connect(mapStateToProps)(Advantages);
