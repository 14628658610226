import React from 'react';
import { MARKS } from "@contentful/rich-text-types";
import { renderRichText } from "@contentful/rich-text-react-renderer";
import {JSON_Parse} from "../../../utils/FormatUtils";


const AdvantagesItem = ({img, imgTitle, name}) => {

    const Bold = ({ children }) => <span className="advantages-item-name advantages-item-name_bold">{children}</span>;

    const options = {
        renderMark: {
            [MARKS.BOLD]: text => <Bold>{text}</Bold>,
        },
    };

    return (
        <div className="advantages-item">
            <img src={img} className="advantages-item__img" alt={imgTitle}/>
            <div className="advantages-item__name">
                  {JSON_Parse(name.raw, options)}
            </div>
        </div>
    );
};



export default AdvantagesItem;
