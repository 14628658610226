import React from 'react';
import {connect} from "react-redux";
import {graphql, StaticQuery} from "gatsby";
import { renderRichText } from "@contentful/rich-text-react-renderer";

import AboutHome from '../components/AboutHome';
import {JSON_Parse} from "../../../utils/FormatUtils";



const AboutHomeContainer = ({languageSelect}) => {
  return (
      <StaticQuery
          query={graphql`
              query {
                contentfulAbout {
                  subtitle_ru
                  subtitle_en
                  subtitle_cn
                  desc_ru
                  desc_en
                  desc_cn
                  descriptionHome_ru{
                    raw
                  }
                  descriptionHome_en{
                    raw
                  }
                  descriptionHome_cn{
                    raw
                  }
                  button_ru
                  button_en
                  button_cn
                  video_youTube
                  video {
                    file {
                      url
                    }
                  }
                  videoHd {
                    file {
                      url
                    }
                  }
                }
              }
            `}
          render={data => {

            return (
                <AboutHome subtitle={data.contentfulAbout["subtitle_" + languageSelect]}
                           descSubtitle={data.contentfulAbout["desc_" + languageSelect]}
                           description={JSON_Parse(data.contentfulAbout['descriptionHome_' + languageSelect].raw)}
                           buttonName={data.contentfulAbout['button_' + languageSelect]}
                />
            )
          }}
      />
  );
};


let mapStateToProps = (state) => {
  return {
    languageSelect: state.languageSelect["languageSelect"],
  }
};

export default connect(mapStateToProps)(AboutHomeContainer);
