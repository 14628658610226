import React from 'react';
import { Link } from 'gatsby';
import Img from '../../../components/Img';
import { BigPlayButton, ControlBar, Player } from 'video-react';

const PortfolioSliderItem = ({
  subtitle,
  detailLink,
  video,
  img,
  buttonDetailName,
}) => {
  return (
    <div className='portfolio-slider__item'>
      <div className='portfolio-slider-item-head'>{subtitle}</div>
      <div className='portfolio-slider-item-content'>
        <Player
          muted={true}
          loop
          autobuffer
          autoPlay
          fluid={true}
          height='100%'
          width='100%'
          src={video}
          playsInline={true}
          poster={img?.url ?? ""}
        >
          <ControlBar
            autoHide={false}
            disableDefaultControls={true}
            disableCompletely={true}
          />
          <BigPlayButton position='center' />
        </Player>
        <Img fluid={img} />
      </div>
      <div className='portfolio-slider-item-footer'>
        <Link to={detailLink} className='portfolio-slider-item__link'>
          {buttonDetailName}
        </Link>
      </div>
    </div>
  );
};

export default PortfolioSliderItem;
