import React from 'react';
import {connect} from "react-redux";
import {graphql, StaticQuery} from "gatsby";
import Slider from "react-slick/lib";
import { renderRichText } from "@contentful/rich-text-react-renderer";

import ReviewsSliderItem from "../components/ReviewsSliderItem";
import {JSON_Parse} from "../../../utils/FormatUtils";


const ReviewsSliderContainer = ({languageSelect}) => {

    let slider = React.createRef();


    let settings = {

        dots: false,
        infinite: true,
        arrows: false,
        slidesToShow: 2,
        slidesToScroll: 2,
        swipeToSlide: true,
        responsive: [
            {
                breakpoint: 1279,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    let nextSlide = () => {
        slider.current.slickNext();
    };

    let prevSlide = () => {
        slider.current.slickPrev();
    };

    return (
        <StaticQuery
            query={graphql`
              query {
                contentfulReviews {
                  subtitle_ru
                  subtitle_en
                  subtitle_cn
                  desc_ru
                  desc_en
                  desc_cn
                  reviewsItems{
                    id
                    subtitle_ru
                    subtitle_en
                    desc_ru{
                        raw
                    }
                    desc_en{
                        raw
                    }
                    desc_cn{
                        raw
                    }
                    company_ru
                    company_en
                    img{
                        title
                       gatsbyImageData
                    }
                  }
                }
              }
            `}
            render={data => {


                const sliderItems = data.contentfulReviews.reviewsItems.map(item => {
                    return (
                        <ReviewsSliderItem key={item.id}
                                           name={languageSelect === 'cn' ? item["subtitle_en"] : item["subtitle_" + languageSelect]}
                                           companyName={languageSelect === 'cn' ? item["company_en"] : item["company_" + languageSelect]}
                                           review={JSON_Parse(item["desc_" + languageSelect].raw)}
                                           img={item.img}
                                           imgAlt={item.img.title}
                        />
                    );
                });
                return (
                    <section className="section section-reviews">
                        <div className="container">
                            <div className="section-head section-reviews__section-head">
                                <h2 className="subtitle subtitle_lvl_two">
                                    {data.contentfulReviews["subtitle_" + languageSelect]}
                                </h2>
                                <div className="subtitle-desc">
                                    {data.contentfulReviews["desc_" + languageSelect]}
                                </div>
                                <div className="section-head-button">
                                    <button onClick={prevSlide} className="reviews-slider__button reviews-slider__button_prev" aria-label="prev">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="93" height="8" viewBox="0 0 93 8" fill="none">
                                            <path d="M0.646447 3.64644C0.451184 3.8417 0.451184 4.15828 0.646447 4.35355L3.82843 7.53553C4.02369 7.73079 4.34027 7.73079 4.53553 7.53553C4.7308 7.34026 4.7308 7.02368 4.53553 6.82842L1.70711 3.99999L4.53553 1.17157C4.7308 0.976303 4.7308 0.659721 4.53553 0.464458C4.34027 0.269196 4.02369 0.269196 3.82843 0.464458L0.646447 3.64644ZM93 3.5L1 3.49999L1 4.49999L93 4.5L93 3.5Z" fill="black"/>
                                        </svg>
                                    </button>
                                    <button onClick={nextSlide} className="reviews-slider__button reviews-slider__button_next" aria-label="next">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="93" height="8" viewBox="0 0 93 8" fill="none">
                                            <path d="M92.3536 4.35356C92.5488 4.1583 92.5488 3.84172 92.3536 3.64645L89.1716 0.464474C88.9763 0.269212 88.6597 0.269212 88.4645 0.464474C88.2692 0.659736 88.2692 0.976318 88.4645 1.17158L91.2929 4.00001L88.4645 6.82843C88.2692 7.0237 88.2692 7.34028 88.4645 7.53554C88.6597 7.7308 88.9763 7.7308 89.1716 7.53554L92.3536 4.35356ZM-4.37114e-08 4.5L92 4.50001L92 3.50001L4.37114e-08 3.5L-4.37114e-08 4.5Z" fill="black"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div className="reviews-slider">
                                <Slider {...settings} ref={slider}>
                                    {sliderItems}
                                </Slider>
                            </div>
                        </div>
                    </section>
                );
            }}
        />

    );
};



let mapStateToProps = (state) => {
    return {
        languageSelect: state.languageSelect["languageSelect"],
    }
};

export default connect(mapStateToProps)(ReviewsSliderContainer);
