import React, { useState } from 'react';
import { connect } from 'react-redux';
import { graphql, StaticQuery } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text'

import Banner from '../components/Banner';
import Img from '../../../components/Img';
import FormFeedbackModal from '../../FormFeedback/containers/FormFeedbackModal';
import { Modal } from '../../../components';
import {JSON_Parse} from "../../../utils/FormatUtils";

const BannerContainer = ({ languageSelect }) => {
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };
  return <div></div>;
  return (
    <StaticQuery
      query={graphql`
        query {
          allContentfulBannners(
            filter: { id: { in: ["fccf909d-61b1-5522-93b2-69eaaff660d1"] } }
          ) {
            edges {
              node {
                id
                subtitle_ru {
                  raw
                }
                subtitle_en {
                  raw
                }
                subtitle_cn {
                  raw
                }
                subtitle_color
                button_ru
                button_en
                button_cn
                button_color
                img {
                  title
                  url
                }
              }
            }
          }
        }
      `}
      render={(data) => {
        return (
          <section className='section section-banner'>
            <Banner>
              <Img
                className='banner__img'
                fluid={data.allContentfulBannners.edges[0].node?.img}
                alt={data.allContentfulBannners.edges[0].node.img.title}
              />
              <div
                className='banner__text'
                style={
                  data.allContentfulBannners.edges[0].node?.subtitle_color && {
                    color:
                      data.allContentfulBannners.edges[0].node?.subtitle_color,
                  }
                }
              >
                {JSON_Parse(
                  data.allContentfulBannners.edges[0].node[
                    'subtitle_' + languageSelect
                  ].raw
                )}
              </div>
              <div className='banner__button'>
                <button
                  className='button'
                  style={
                    data.allContentfulBannners.edges[0].node.button_color && {
                      borderColor:
                        data.allContentfulBannners.edges[0].node.button_color,
                    }
                  }
                  onClick={() => setIsOpen(true)}
                >
                  <div
                    className='button-inner'
                    style={
                      data.allContentfulBannners.edges[0].node.button_color && {
                        borderColor:
                          data.allContentfulBannners.edges[0].node.button_color,
                      }
                    }
                  >
                    {
                      data.allContentfulBannners.edges[0].node[
                        'button_' + languageSelect
                      ]
                    }
                  </div>
                </button>
              </div>

              <Modal
                title={
                  data.allContentfulBannners.edges[0].node[
                    'button_' + languageSelect
                  ]
                }
                isOpen={isOpen}
                onCancel={closeModal}
              >
                <FormFeedbackModal formPage={'Баннер на главной'} />
              </Modal>
            </Banner>
          </section>
        );
      }}
    />
  );
};

let mapStateToProps = (state) => {
  return {
    languageSelect: state.languageSelect['languageSelect'],
  };
};

export default connect(mapStateToProps)(BannerContainer);
