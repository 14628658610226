import React from 'react';
import { connect } from 'react-redux';
import { graphql, StaticQuery } from 'gatsby';
import { renderRichText } from 'gatsby-source-contentful/rich-text'

import MainBlock from '../components/Main';
import {JSON_Parse} from "../../../utils/FormatUtils";

const MainBlockContainer = ({ languageSelect }) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          contentfulHomeScreen {
            menu {
              id
              name_ru
              name_en
              name_cn
              path
            }
            subtitle_ru {
              raw
            }
            subtitle_en {
              raw
            }
            subtitle_cn {
              raw
            }

            desc_ru {
              raw
            }
            desc_en {
              raw
            }
            desc_cn {
              raw
            }

            button_ru
            button_en
            button_cn

            videoBg {
              file {
                url
              }
            }
            imgBg {
              url
            }
          }
        }
      `}
      render={(data) => {
        return (
          <MainBlock
            // subtitle={
            //   JSON_Parse(
            //     data.contentfulHomeScreen['subtitle_' + languageSelect].raw
            //   )[0].props.children
            // }
            subtitle={
              JSON_Parse(
                data.contentfulHomeScreen['subtitle_' + languageSelect].raw
              )
            }
            desc={JSON_Parse(
              data.contentfulHomeScreen['desc_' + languageSelect].raw
            )}
            buttonName={data.contentfulHomeScreen['button_' + languageSelect]}
            videoBg={data.contentfulHomeScreen.videoBg.file.url}
            videoPoster={data.contentfulHomeScreen.imgBg.url}
          />
        );
      }}
    />
  );
};

let mapStateToProps = (state) => {
  return {
    languageSelect: state.languageSelect['languageSelect'],
  };
};

export default connect(mapStateToProps)(MainBlockContainer);
