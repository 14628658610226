import React from "react";
import {connect} from "react-redux";
import {graphql, StaticQuery} from "gatsby";
import "../scss/index.scss";


import {Layout, Seo } from "../components";
import SectionMain from '../modules/Main/containers/MainContainer';
// import SectionConstructor from '../modules/Constructor/containers/ConstructorContainer';
import SectionAbout from '../modules/About/containers/AboutHome';
import SectionPortfolio from '../modules/PortfolioSlider/containers/PortfolioSliderContainer';
import SectionPartners from '../modules/Partners/containers/PartnersContainer';
import SectionAwards from '../modules/Awards/containers/AwardsContainer';
import SectionServices from '../modules/Services/containers/ServicesContainer';
import BannerFeedback from '../modules/Banner/containers/BannerContainer';
import BannerAdvantages from '../modules/Banner/containers/BannerAdvantages';
import SectionReviews from '../modules/ReviewsSlider/containers/ReviewsSliderContainer';
import SectionFeedback from '../modules/FormFeedback/containers/FormFeedback';



const IndexPage = ({languageSelect}) => {
    return (
        <StaticQuery
            query={graphql`
              query  {
                contentfulHomeScreen {
                    id
                    seo {
                      title_ru
                      title_en
                      title_cn
                      description_ru
                      description_en
                      description_cn
                      keywords_ru
                      keywords_en
                      keywords_cn
                    }
                  }
              }
            `}
            render={data => {
                return (
                    <Layout>
                        <Seo title={`${data.contentfulHomeScreen.seo["title_" + languageSelect]}`}
                             description={`${data.contentfulHomeScreen.seo["description_" + languageSelect]}`}
                             keywords={`${data.contentfulHomeScreen.seo["keywords_" + languageSelect]}`}
                        />
                        <SectionMain/>
                        {/* <SectionConstructor/> */}
                        <SectionAbout/>
                        <SectionPartners/>
                        <SectionPortfolio/>
                        <SectionServices/>
                        <SectionAwards/>
                        <BannerFeedback/>
                        <SectionReviews/>
                        <SectionFeedback/>
                    </Layout>
                );
            }}
        />



    );
};


let mapStateToProps = (state) => {
    return {
        languageSelect: state.languageSelect["languageSelect"],
    }
};

export default connect(mapStateToProps)(IndexPage);
