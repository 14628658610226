import React from 'react';
import {connect} from "react-redux";
import {graphql, StaticQuery} from "gatsby";
import Slider from "react-slick/lib";

import PartnersItem from '../components/PartnersItem';

const PartnersContainer = ({languageSelect}) => {
    let settings = {


        dots: false,
        infinite: true,
        arrows: false,
        slidesToShow: 5,
        slidesToScroll: 1,
        swipeToSlide: true,
        /*focusOnSelect: true,*/
        autoplay: true,
        autoplaySpeed: 2500,
        responsive: [
            {
                breakpoint: 1349,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };
    return (
        <StaticQuery
            query={graphql`
              query {
                contentfulPartners {
                  subtitle_ru
                  subtitle_en
                  subtitle_cn
                  slider{
                    id
                    img{
                       gatsbyImageData
                    }
                  }
                }
              }
            `}
            render={data => {

                const partnersItems = data.contentfulPartners.slider.map(item => {
                    return (
                        <PartnersItem key={item.id} img={item.img}/>
                    );
                });

                return (
                    <section className="section">
                        <div className="container">
                            <div className="section-head">
                                <h2 className="subtitle subtitle_lvl_two">{data.contentfulPartners["subtitle_" + languageSelect]}</h2>
                            </div>
                            <div className="partners-slider">
                                <Slider {...settings}>
                                    {partnersItems}
                                </Slider>
                            </div>
                        </div>
                    </section>
                )
            }}
        />

    );
};


let mapStateToProps = (state) => {
    return {
        languageSelect: state.languageSelect["languageSelect"],
    }
};

export default connect(mapStateToProps)(PartnersContainer);
